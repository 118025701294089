@font-face {
font-family: '__alomni_2274ae';
src: url(/_next/static/media/df5504630f90ab76-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
}

@font-face {
font-family: '__alomni_2274ae';
src: url(/_next/static/media/fc65912098a1d48c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
}

@font-face {
font-family: '__alomni_2274ae';
src: url(/_next/static/media/8b68c6a3d8bae748-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
}@font-face {font-family: '__alomni_Fallback_2274ae';src: local("Arial");ascent-override: 94.79%;descent-override: 34.54%;line-gap-override: 30.41%;size-adjust: 92.01%
}.__className_2274ae {font-family: '__alomni_2274ae', '__alomni_Fallback_2274ae'
}.__variable_2274ae {--alomni: '__alomni_2274ae', '__alomni_Fallback_2274ae'
}

